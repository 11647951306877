<template>
	<div class="registro_de_vendas">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Registro de Vendas</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
				<v-dialog scrollable v-model="showDialogCadastro" max-width="700">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed dark color="indigo darken-4" v-bind="attrs" v-on="on">
							<v-icon left>add</v-icon>
							<span>Nova Venda</span>
						</v-btn>
					</template>
					<v-card height="fit-content">
						<v-system-bar color="blue darken-4"></v-system-bar>

						<v-card-title class="headline grey--text text--darken-2">Cadastrar nova venda</v-card-title>

						<v-card-text>
							<v-form class="my-3" ref="formCadastramento">
								<v-row>
									<v-col cols="12">
										<v-text-field prepend-icon="mdi-text" label="Observação da venda" v-model="cadastro.observacao" counter maxlength="100" clearable></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-menu ref="calendarDataVenda" v-model="menuOpenDataVenda" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="dataVendaToBR" label="Data da Venda" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
											</template>
											<v-date-picker v-model="cadastro.data_venda" no-title scrollable locale="pt-br" @change="menuOpenDataVenda = false">
												<v-spacer></v-spacer>
												<v-btn text color="primary" @click="menuOpenDataVenda = false">Cancelar</v-btn>
												<v-btn text color="primary" @click="$refs.calendarDataVenda.save(cadastro.data_venda)">OK</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-account-tie" label="Vendedor" v-model="cadastro.id_usuario_vendedor" :items="data.listagemVendedores" item-text="nome" item-value="id_usuario" :rules="rules.selectValidate" clearable v-if="[1, 2].indexOf(parseInt(session.id_cargo_usuario)) >= 0"></v-autocomplete>
										<v-autocomplete prepend-icon="mdi-account-tie" label="Vendedor" v-model="session.id_usuario.toString()" :items="data.listagemVendedores" item-text="nome" item-value="id_usuario" readonly v-else></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-office-building" label="Unidade Operacional" v-model="cadastro.id_unidade_operacional" :items="listagemUnidadesDoVendedorSelecionado" item-text="nome_fantasia" item-value="id_unidade_operacional" :rules="rules.selectValidate" clearable></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-account-box-multiple" label="Tipo do Cliente" v-model="cadastro.id_tipo_cliente" :items="data.listagemTiposClientes" item-text="desc_tipo_cliente" item-value="id_tipo_cliente" :rules="rules.selectValidate" clearable @change="buscarValorProdutoDoCliente"></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-office-building" label="Cliente" v-model="cadastro.id_cliente" :items="data.listagemClientesPessoaJuridica" item-text="desc_cliente" item-value="id_cliente" :rules="rules.selectValidate" clearable v-if="cadastro.id_tipo_cliente == 1" @change="buscarValorProdutoDoCliente"></v-autocomplete>
										<v-autocomplete prepend-icon="mdi-card-text-outline" label="Cliente" v-model="cadastro.id_cliente" :items="data.listagemClientesPessoaFisica" item-text="desc_cliente" item-value="id_cliente" :rules="rules.selectValidate" clearable v-else-if="cadastro.id_tipo_cliente == 2" @change="buscarValorProdutoDoCliente"></v-autocomplete>
										<v-autocomplete prepend-icon="mdi-clipboard-text" label="Cliente" clearable disabled v-else></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-select prepend-icon="mdi-map-marker-outline" label="Endereço de entrega diferente do cadastro do cliente?" v-model="cadastro.endereco_diferente" :items="['Não', 'Sim']"></v-select>
									</v-col>
									<v-col cols="12" v-if="cadastro.endereco_diferente == 'Sim'">
										<v-text-field prepend-icon="mdi-map-marker-radius-outline" label="Novo endereço de entrega" v-model="cadastro.endereco" :rules="rules.inputTextValidate" counter maxlength="100" clearable></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-package-variant" label="Produto" v-model="cadastro.id_produto" :items="data.listagemProdutos" item-text="desc_produto" item-value="id_produto" :rules="rules.selectValidate" clearable @change="changeProduto"></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-multiplication" label="Quantidade" v-model="cadastro.quantidade" :type="'number'" min="1" @change="preloadFieldsAutorizacaoEmplacamento"></v-text-field>
									</v-col>
								</v-row>
								<v-row v-for="i in Number(cadastro.quantidade)" v-bind:key="i">
									<v-col cols="6" v-if="produtosQueExigemPlaca.indexOf(Number(cadastro.id_produto)) >= 0">
										<v-text-field prepend-icon="mdi-counter" :label="`Identificação da Placa ${i}`" v-model="cadastro.placas[i]" counter maxlength="8" clearable @input="cadastro.placas[i] = $event.toUpperCase()"></v-text-field>
									</v-col>
									<v-col cols="6" v-if="produtosQueVinculaAdicionalAutorizacaoEmplacamento.indexOf(Number(cadastro.id_produto)) >= 0">
										<v-select prepend-icon="mdi-label" :label="`Adicional Autorização Emplacamento? (R$ ${Number(data.valorAtualAutorizacaoEmplacamento.valor).toFixed(2)})`" v-model="cadastro.autorizacao_emplacamento[i]" :items="['Não', 'Sim']"></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col :cols="(produtosQueVinculaAdicionalAutorizacaoEmplacamento.indexOf(Number(cadastro.id_produto)) >= 0) ? 4 : 6">
										<v-text-field prepend-icon="mdi-currency-brl" label="Valor Unitário" v-model="cadastro.valor_unitario" :type="'number'" step="0.01"></v-text-field>
									</v-col>
									<v-col cols="4" v-if="produtosQueVinculaAdicionalAutorizacaoEmplacamento.indexOf(Number(cadastro.id_produto)) >= 0">
										<v-text-field prepend-icon="mdi-currency-brl" label="Valor AE" v-model="valor_total_autorizacao_emplacamento" :type="'number'" step="0.01" readonly></v-text-field>
									</v-col>
									<v-col :cols="(produtosQueVinculaAdicionalAutorizacaoEmplacamento.indexOf(Number(cadastro.id_produto)) >= 0) ? 4 : 6">
										<v-text-field prepend-icon="mdi-currency-brl" label="Valor Total" v-model="valor_total_produto_selecionado_e_quantidade_por_cliente" :type="'number'" step="0.01" readonly></v-text-field>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>

						<v-card-actions class="my-5">
							<v-btn depressed dark color="blue darken-4" @click="showDialogCadastro = !showDialogCadastro">
								<v-icon left>close</v-icon>
								<span>Cancelar</span>
							</v-btn>
							<v-btn depressed dark color="indigo darken-4" @click="gatilhoSubmitCadastro" :loading="loadingStatus">
								<v-icon left>save</v-icon>
								<span>Salvar</span>
							</v-btn>
							<v-btn depressed dark color="indigo darken-4" @click="gatilhoSubmitCadastroNovaVenda" :loading="loadingStatus">
								<v-icon left>save</v-icon>
								<span>Salvar e lançar nova venda</span>
							</v-btn>
						</v-card-actions>

						<v-system-bar color="blue-grey darken-1"></v-system-bar>
					</v-card>
				</v-dialog>
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
						
					<v-flex xs12 class="pa-3">
						<v-row>
							<v-flex xs12 class="pr-3">
								<h5>Filtros Pessoa Física e Jurídica</h5>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="CPF / CNPJ" v-model="filters.cnpj_cpf" outlined dense counter clearable maxlength="14"></v-text-field>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-autocomplete label="Nome / Nome Fantasia" v-model="filters.nome" outlined dense clearable :items="data.listagemClientesPessoaFisicaEJuridica" item-text="nome" item-value="nome"></v-autocomplete>
							</v-flex>
						</v-row>
					</v-flex>

					<v-flex xs12 class="pa-3">
						<v-row>
							<v-flex xs12 class="pr-3">
								<h5>Filtros Gerais</h5>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-autocomplete label="Ano" v-model="filters.ano" :items="data.listaAnosComRegistroDeVenda" item-text="ano" item-value="ano" outlined dense></v-autocomplete>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
		                        <v-select :items="mesesComId" label="Meses" item-text="desc_mes" item-value="id_mes" v-model="filters.meses" multiple dense outlined clearable>
		                        	<template v-slot:prepend-item>
										<v-list-item ripple @mousedown.prevent @click="toggleSelecionarTodosMeses">
											<v-list-item-action>
												<v-icon :color="filters.meses.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
											</v-list-item-action>
											<v-list-item-content>
												<v-list-item-title>Selecionar Tudo</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-divider class="mt-2"></v-divider>
									</template>
		                        </v-select>
		                    </v-flex>
		                    <v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-menu ref="menu" v-model="menuOpenFiltroCriacao" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="dataVendaToBRFiltroCriacao" label="Data da Venda" v-bind="attrs"v-on="on" readonly outlined dense clearable></v-text-field>
									</template>
									<v-date-picker v-model="filters.data_venda" no-title scrollable locale="pt-br" @change="menuOpenFiltroCriacao = false">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenFiltroCriacao = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.menu.save(filters.data_venda)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-flex>
		                    <v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-menu ref="menu" v-model="menuOpenFiltroPagamento" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="dataToBRFiltroPagamento" label="Data de Pagamento" v-bind="attrs"v-on="on" readonly outlined dense clearable></v-text-field>
									</template>
									<v-date-picker v-model="filters.data_pagamento" no-title scrollable locale="pt-br" @change="menuOpenFiltroPagamento = false">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenFiltroPagamento = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.menu.save(filters.data_pagamento)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3" v-if="[1, 2].indexOf(parseInt(session.id_cargo_usuario)) >= 0">
								<v-autocomplete label="Vendedor" v-model="filters.nome_vendedor" :items="data.listagemVendedores" item-text="nome" item-value="nome" outlined dense clearable></v-autocomplete>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-autocomplete label="Unidade Operacional" v-model="filters.nome_fantasia_unidade_operacional" :items="data.listagemUnidadesOperacionais" item-text="nome_fantasia" item-value="nome_fantasia" outlined dense clearable></v-autocomplete>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-autocomplete label="Produto" v-model="filters.desc_produto" :items="data.listagemProdutos" item-text="desc_produto" item-value="desc_produto" outlined dense clearable></v-autocomplete>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="Identificação da Placa" v-model="filters.placa" outlined dense clearable></v-text-field>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-autocomplete label="Status" v-model="filters.desc_status" :items="data.listagemStatusVendas" item-text="desc_status" item-value="desc_status" outlined dense clearable></v-autocomplete>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="Endereço entrega" v-model="filters.endereco" outlined dense clearable></v-text-field>
							</v-flex>
							<v-flex xs6 sm4 md4 lg3 class="pr-3">
								<v-text-field label="Observação" v-model="filters.observacao" outlined dense clearable></v-text-field>
							</v-flex>
						</v-row>
					</v-flex>

					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-data-table
					:headers="listagemHeadersPessoaFisicaEJuridica"
					:items="data.listagemPessoaFisicaEJuridica"
					:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
					class="grey lighten-4 grey--text text--darken-2"
					style="white-space:nowrap;"
				>
					<template v-slot:item.actions="{ item }">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
									<v-icon>menu</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item v-if="item.id_status == 3">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="editarVenda(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-pencil</v-icon> Editar venda
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_status == 3">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="excluirVenda(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-trash-can-outline</v-icon> Excluir venda
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_status == 3">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="emitirFaturaCliente(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-file-certificate-outline</v-icon> Emitir fatura para o cliente
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="[1, 2].indexOf(parseInt(item.id_status)) >= 0">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="abrirArquivo('arquivos_fatura', item.arquivo_fatura)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-download</v-icon> Ver fatura
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_status == 1">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="abrirArquivo('arquivos_comprovante', item.arquivo_comprovante)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-download</v-icon> Ver comprovante pagamento
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_status == 2">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="registrarPagamento(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-check-all</v-icon> Registrar pagamento da fatura
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_status == 3">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="registrarPagamentoSemFatura(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-check-all</v-icon> Registrar pagamento da fatura
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_status == 2">
									<router-link to="#">
										<v-list-item-title class="grey--text text--darken-2" @click="cancelarFatura(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-trash-can-outline</v-icon> Cancelar fatura
										</v-list-item-title>
									</router-link>
								</v-list-item>
							</v-list>
						</v-menu>
					</template>
					<template v-slot:item.desc_status="{ item }">
						<v-chip class="ma-2 white--text text--white" color="success" v-if="item.id_status == 1">{{item.desc_status}}</v-chip>
						<v-chip class="ma-2 white--text text--white" color="light-blue" v-if="item.id_status == 2">{{item.desc_status}}</v-chip>
						<v-chip class="ma-2 white--text text--white" color="red darken-1" v-if="item.id_status == 3">{{item.desc_status}}</v-chip>
					</template>
					<template v-slot:item.cnpj_cpf="{ item }">
						{{ mascara_cnpj_cpf(item.cnpj_cpf) }}
					</template>
					<template v-slot:item.valor_unitario="{ item }">
						R$ {{v_number_format(item.valor_unitario, 2, ',', '.')}}
					</template>
					<template v-slot:item.autorizacao_emplacamento="{ item }">
						<v-chip class="ma-2 white--text text--white" :color="`${(item.autorizacao_emplacamento == 'Sim') ? 'light-blue' : 'default'}`">{{item.autorizacao_emplacamento}}</v-chip>
					</template>
					<template v-slot:item.valor_autorizacao_emplacamento="{ item }">
						R$ {{v_number_format(item.valor_autorizacao_emplacamento, 2, ',', '.')}}
					</template>
					<template v-slot:item.valor_total="{ item }">
						R$ {{v_number_format(item.valor_total, 2, ',', '.')}}
					</template>
					<!-- <template v-slot:item.dt_registro_pagamento="{ item }">
						{{ (item.dt_registro_pagamento) ? new Date(item.dt_registro_pagamento).toLocaleDateString("pt-BR") : '' }}
					</template> -->
				</v-data-table>
				<v-flex xs12 class="text-end">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
								<v-icon>file_download</v-icon>
								<span>Exportar planilha</span>
							</v-btn>
						</template>
						<v-list>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral(data.listagemPessoaFisicaEJuridica)">
										<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
									</v-list-item-title>
								</router-link>
							</v-list-item>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
									</v-list-item-title>
								</router-link>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-flex>
			</v-flex>
		</v-layout>

		<v-dialog v-model="showDialogEdit" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Editar Venda</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formEdicao">
						<v-row>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-text" label="Observação da venda" v-model="dadosEdicao.observacao" counter maxlength="100" clearable></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-menu ref="calendarDataVendaEdicao" v-model="menuOpenDataVendaEdicao" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="dataVendaToBREdicao" label="Data da Venda" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="dadosEdicao.data_venda_sem_formatacao" no-title scrollable locale="pt-br" @change="menuOpenDataVendaEdicao = false">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenDataVendaEdicao = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.calendarDataVendaEdicao.save(dadosEdicao.data_venda_sem_formatacao)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="6">
								<v-select prepend-icon="mdi-map-marker-outline" label="Endereço de entrega diferente do cadastro do cliente?" v-model="dadosEdicao.endereco_diferente" :items="['Não', 'Sim']"></v-select>
							</v-col>
							<v-col cols="12" v-if="dadosEdicao.endereco_diferente == 'Sim'">
								<v-text-field prepend-icon="mdi-map-marker-radius-outline" label="Endereço" v-model="dadosEdicao.endereco" counter maxlength="100" :rules="rules.inputTextValidate"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-autocomplete prepend-icon="mdi-package-variant" label="Produto" v-model="dadosEdicao.id_produto" :items="data.listagemProdutos" item-text="desc_produto" item-value="id_produto" readonly></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-currency-brl" label="Valor Unitário" v-model="dadosEdicao.valor_unitario" :type="'number'" step="0.01" readonly></v-text-field>
							</v-col>
							<v-col cols="12" v-if="produtosQueExigemPlaca.indexOf(Number(dadosEdicao.id_produto)) >= 0">
								<v-text-field prepend-icon="mdi-counter" :label="`Identificação da Placa`" v-model="dadosEdicao.placa" counter maxlength="8" clearable  @input="dadosEdicao.placa = $event.toUpperCase()"></v-text-field>
							</v-col>
							<v-col cols="6" v-if="produtosQueVinculaAdicionalAutorizacaoEmplacamento.indexOf(Number(dadosEdicao.id_produto)) >= 0">
								<v-select prepend-icon="mdi-label" :label="`Adicional Autorização Emplacamento`" v-model="dadosEdicao.autorizacao_emplacamento" :items="['Não', 'Sim']" @change="atualizarValorAutorizacaoEmplacamento"></v-select>
							</v-col>
							<v-col cols="6" v-if="produtosQueVinculaAdicionalAutorizacaoEmplacamento.indexOf(Number(dadosEdicao.id_produto)) >= 0">
								<v-text-field prepend-icon="mdi-currency-brl" label="Valor AE" v-model="dadosEdicao.valor_autorizacao_emplacamento" :type="'number'" step="0.01"></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogEdit = !showDialogEdit">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitEdicao()" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogPagamento" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Registrar pagamento da fatura</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formPagamento" enctype="multipart/form-data" method="post">
						<v-row>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-clipboard-text" label="Descritivo" v-model="dadosPagamento.descritivo_pagamento" counter maxlength="100"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-autocomplete prepend-icon="mdi-credit-card-multiple-outline" label="Forma de Pagamento" v-model="dadosPagamento.id_forma_pagamento" :items="data.listagemFormasPagamento" item-text="forma_pagamento" item-value="id_forma_pagamento" :rules="rules.selectValidate" clearable></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-menu ref="calendarPgamento" v-model="menuOpenPagamento" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="data_pagamentoToBR" label="Data Pagamento" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="dadosPagamento.data_pagamento" no-title scrollable locale="pt-br" @change="menuOpenPagamento = false">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenPagamento = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.calendarPgamento.save(dadosPagamento.data_pagamento)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12">
								<v-file-input accept=".jpg, .jpeg, .png, .pdf" label="Anexar arquivo de comprovante (.jpg, .jpeg, .png, .pdf)" v-model="fileNameholder" show-size @change="fileSelectedPagamento" prepend-icon="insert_drive_file"></v-file-input>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogPagamento = !showDialogPagamento">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitRegistroPagamento()" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Registrar Pagamento</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogPagamentoSemFatura" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Registrar pagamento da venda</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formPagamento" enctype="multipart/form-data" method="post">
						<v-row>
							<v-col cols="12">
								<v-text-field prepend-icon="mdi-clipboard-text" label="Descritivo" v-model="dadosPagamentoSemFatura.descritivo_pagamento" counter maxlength="100" clearable></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-autocomplete prepend-icon="mdi-credit-card-multiple-outline" label="Forma de Pagamento" v-model="dadosPagamentoSemFatura.id_forma_pagamento" :items="data.listagemFormasPagamento" item-text="forma_pagamento" item-value="id_forma_pagamento" :rules="rules.selectValidate" clearable></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-menu ref="calendarPgamento" v-model="menuOpenPagamentoSemFatura" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="data_pagamentoToBRSemFatura" label="Data Pagamento" prepend-icon="mdi-calendar" readonly v-bind="attrs"v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="dadosPagamentoSemFatura.data_pagamento" no-title scrollable locale="pt-br" @change="menuOpenPagamentoSemFatura = false">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuOpenPagamentoSemFatura = false">Cancelar</v-btn>
										<v-btn text color="primary" @click="$refs.calendarPgamento.save(dadosPagamentoSemFatura.data_pagamento)">OK</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12">
								<v-file-input accept=".jpg, .jpeg, .png, .pdf" label="Anexar arquivo de comprovante (.jpg, .jpeg, .png, .pdf)" v-model="fileNameholder" show-size @change="fileSelectedPagamentoSemFatura" prepend-icon="insert_drive_file"></v-file-input>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogPagamentoSemFatura = !showDialogPagamentoSemFatura">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitRegistroPagamentoSemFatura()" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Registrar Pagamento</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogExcluir" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Excluir Venda</v-card-title>

				<v-card-text>
					<v-alert prominent text type="warning" border="left" icon="mdi-alert-outline">
						A venda será excluída. Esta ação não poderá ser desfeita.
					</v-alert>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogExcluir = !showDialogExcluir">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitExclusao()" :loading="loadingStatus">
						<v-icon left>mdi-trash-can-outline</v-icon>
						<span>Excluir</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogCancelarFatura" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Cancelar Fatura</v-card-title>

				<v-card-text>
					<v-alert prominent text type="warning" border="left" icon="mdi-alert-outline">
						A fatura será excluída e esta ação não poderá ser desfeita. As vendas vinculadas retornarão para o status "Em Aberto" e poderão ser vinculadas a novas faturas.
					</v-alert>

			        <v-alert dense text color="orange darken-2" border="left" icon="mdi-information-outline">
						<h5>Caso exista um lançamento vinculado a essa fatura no fluxo de Finanças do Cliente, também será excluído.</h5>
					</v-alert>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogCancelarFatura = !showDialogCancelarFatura">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitCancelarFatura()" :loading="loadingStatus">
						<v-icon left>mdi-trash-can-outline</v-icon>
						<span>Efetuar Cancelamento</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogEmissaoFatura" max-width="1000px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Emitir Fatura para {{dadosEmissaoFatura.nome_cliente}}</v-card-title>

				<v-card-text>
					<h3 class="mb-3">Selecione abaixo as vendas em aberto que farão parte desta fatura:</h3>
					
					<v-data-table
			            v-model="vendasSelecionadas"
			            :headers="listHeadersVendasCliente"
			            :items="listagemVendasEmAbertoDoCliente"
			            item-key="id_venda"
			            :items-per-page="5"
			            show-select
			        >
			        	<template v-slot:item.valor_unitario="{ item }">
							R$ {{v_number_format(item.valor_unitario, 2, ',', '.')}}
						</template>
						<template v-slot:item.valor_autorizacao_emplacamento="{ item }">
							R$ {{v_number_format(item.valor_autorizacao_emplacamento, 2, ',', '.')}}
						</template>
						<template v-slot:item.valor_total="{ item }">
							R$ {{v_number_format(item.valor_total, 2, ',', '.')}}
						</template>
						<template v-slot:item.dt_criacao="{ item }">
							{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
						</template>
			        </v-data-table>

			        <v-row>
			        	<v-col cols="12">
					        <v-alert prominent text color="info" border="left" icon="mdi-cash">
								<h3>Saldo financeiro acumulado do cliente</h3>
								<p>R$ {{v_number_format(saldoFinanceiroCliente, 2, ',', '.')}}</p>
							</v-alert>
						</v-col>
					</v-row>

			        <h3 class="mb-3">Desconto ou acréscimo na fatura:</h3>

			        <v-row>
			        	<v-col cols="4">
							<v-select prepend-icon="mdi-cash-refund" label="Utilizar saldo do cliente?" v-model="utilizarSaldoFinanceiroCliente" :items="['Não', 'Sim']" :disabled="Number(saldoFinanceiroCliente) <= 0" @change="calcularFaturaComSaldoFinanceiroCliente"></v-select>
						</v-col>
			        	<v-col cols="4">
							<v-text-field prepend-icon="mdi-card-text" label="Descritivo" v-model="desconto_acrescimo_descritivo" counter maxlength="100" clearable></v-text-field>
						</v-col>
			        	<v-col cols="4">
			        		<v-tooltip bottom color="primary">
			        			<template v-slot:activator="{ on, attrs }">
									<v-text-field prepend-icon="mdi-currency-brl" label="Valor" v-model="desconto_acrescimo_valor" :type="'number'" step="0.01" clearable v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<span>Utilize sinal de menos (-) para lançamento de desconto</span>
							</v-tooltip>
						</v-col>
					</v-row>

					<v-row v-if="utilizarSaldoFinanceiroCliente == 'Sim'">
			        	<v-col cols="12">
					        <v-alert dense text color="orange darken-2" border="left" icon="mdi-information-outline">
								<h5>Um lançamento de {{(desconto_acrescimo_valor < 0) ? 'desconto' : 'crédito'}} no valor de R$ {{v_number_format(((desconto_acrescimo_valor < 0) ? desconto_acrescimo_valor * -1 : desconto_acrescimo_valor), 2, ',', '.')}} será registrado no fluxo de Finanças do Cliente vinculado à essa fatura.</h5>
							</v-alert>
						</v-col>
					</v-row>

					<v-row>
			        	<v-col cols="6">
					        <v-alert prominent text color="info" border="left" icon="mdi-cash">
								<h3>Subtotal da Fatura</h3>
								<p>R$ {{v_number_format(valorSubTotalDaFatura, 2, ',', '.')}}</p>
							</v-alert>
						</v-col>
						<v-col cols="6">
					        <v-alert prominent text color="info" border="left" icon="mdi-cash-multiple">
								<h3>Total da Fatura</h3>
								<p>R$ {{v_number_format(valorTotalDaFatura, 2, ',', '.')}}</p>
							</v-alert>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogEmissaoFatura = !showDialogEmissaoFatura">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitCriacaoFatura()" :loading="loadingStatus" v-if="vendasSelecionadas.length">
						<v-icon left>mdi-file-check</v-icon>
						<span>Emitir Fatura</span>
					</v-btn>
					<v-btn depressed disabled color="indigo darken-4"v-else>
						<v-icon left>mdi-file-check</v-icon>
						<span>Emitir Fatura</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo_usuario: Number(localStorage.getItem('id_cargo_usuario')),
				id_usuario: Number(localStorage.getItem('id_usuario'))
			},
			cadastro: {
				data_venda: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				observacao: '',
				id_usuario_vendedor: 0,
				id_unidade_operacional: 0,
				id_tipo_cliente: 0,
				id_cliente: 0,
				id_produto: 0,
				quantidade: 1,
				endereco_diferente: 'Não',
				endereco: '',
				placas: [],
				autorizacao_emplacamento: [],
				valor_unitario: 0,
				lancarNovaVendaMesmoCliente: false
			},
			cadastro_resetado: {
				data_venda: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				observacao: '',
				id_usuario_vendedor: 0,
				id_unidade_operacional: 0,
				id_tipo_cliente: 0,
				id_cliente: 0,
				id_produto: 0,
				quantidade: 1,
				endereco_diferente: 'Não',
				endereco: '',
				placas: [],
				autorizacao_emplacamento: [],
				valor_unitario: 0,
				lancarNovaVendaMesmoCliente: false
			},
			rules: {
				inputTextValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v.length > 0 || 'Campo obrigatório'
				],
				selectValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v != null || 'Campo obrigatório'
				]
			},
			data: {
				listagemGeral: [{cnpj: 'Buscando...'}],
				listagemPessoaFisicaEJuridica: [{cnpj_cpf: 'Buscando...'}],
				listagemTiposClientes: [{desc_tipo_cliente: 'Buscando...'}],
				listagemClientesPessoaFisica: [{desc_cliente: 'Buscando...'}],
				listagemClientesPessoaJuridica: [{desc_cliente: 'Buscando...'}],
				listagemClientesPessoaFisicaEJuridica: [{nome: 'Buscando...'}],
				listagemVendedores: [{nome: 'Buscando...'}],
				listagemProdutos: [{desc_produto: 'Buscando...'}],
				listagemUnidadesOperacionais: [{nome_fantasia: 'Buscando...'}],
				listagemUnidadesPorVendedor: [{nome_fantasia: 'Buscando...'}],
				listaAnosComRegistroDeVenda: [{ano: 'Buscando...'}],
				listagemStatusVendas: [{desc_status: 'Buscando...'}],
				listagemFormasPagamento: [{forma_pagamento: 'Buscando...'}],
				valorAtualAutorizacaoEmplacamento: {}
			},
			filters: {
				nome: '',
				observacao: '',
				cnpj_cpf: '',
				nome_vendedor: '',
				desc_produto: '',
				nome_fantasia_unidade_operacional: '',
				endereco: '',
				placa: '',
				ano: new Date().getFullYear().toString(),//por padrão traz o ano atual
				meses: [new Date().getMonth() + 1],//por padrão traz o mes atual
				criacao: '',
				data_pagamento: '',
				data_venda: ''
			},

			ano_anteriormente_selecionado: new Date().getFullYear().toString(),
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			meses: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],

            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			menuOpenDataVenda: false,
			menuOpenDataVendaEdicao: false,

			loadingStatus: false,
			loadingStatusExport: false,
			showDialogCadastro: false,
			
			dadosEdicao: {},
			showDialogEdit: false,

			dadosExclusao: {},
			showDialogExcluir: false,

			dadosCancelarFatura: {},
			showDialogCancelarFatura: false,

			dadosPagamento: {
				id_fatura: 0,
				id_forma_pagamento: 0,
				descritivo_pagamento: '',
				data_pagamento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				arquivo_comprovante: null
			},
			dadosPagamento_resetado: {
				id_fatura: 0,
				id_forma_pagamento: 0,
				descritivo_pagamento: '',
				data_pagamento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				arquivo_comprovante: null
			},
			showDialogPagamento: false,
			dadosPagamentoSemFatura: {
				id_venda: 0,
				id_cliente: 0,
				id_tipo_cliente: 0,
				id_forma_pagamento: 0,
				descritivo_pagamento: '',
				data_pagamento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				arquivo_comprovante: null
			},
			dadosPagamentoSemFatura_resetado: {
				id_venda: 0,
				id_cliente: 0,
				id_tipo_cliente: 0,
				id_forma_pagamento: 0,
				descritivo_pagamento: '',
				data_pagamento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				arquivo_comprovante: null
			},
			showDialogPagamentoSemFatura: false,

			dadosEmissaoFatura: {},
			vendasSelecionadas: [],
			listagemVendasEmAbertoDoCliente: [{id_venda: 'Buscando...'}],
			desconto_acrescimo_descritivo: '',
			desconto_acrescimo_valor: 0,
			saldoFinanceiroCliente: 0,
			utilizarSaldoFinanceiroCliente: 'Não',
			showDialogEmissaoFatura: false,

			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			menuOpenFiltroCriacao: false,
			menuOpenFiltroPagamento: false,
			menuOpenPagamento: false,
			menuOpenPagamentoSemFatura: false,

			fileNameholder: null
		}
	},
	watch: {
		'filters.ano': {
			handler (newValue, oldValue) {//quando muda o ano, busca a listagem do ano selecionado
				if (newValue != oldValue) {
					this.buscaListaComFiltros();
				}
			},
			deep: true
		},
		'filters.meses': {
			handler (newValue, oldValue) {//quando muda o ano, busca a listagem do ano selecionado
				if (newValue != oldValue) {
					this.buscaListaComFiltros();
				}
			},
			deep: true
		}
	},
	methods: {
		toggleSelecionarTodosMeses () {
			this.$nextTick(() => {
				if (this.todosMesesSelecionados) {
					this.filters.meses = []
				} else {
					this.filters.meses = this.mesesComId.map(function(item){
						return item.id_mes;
					});
				}
			});
		},
		fileSelectedPagamento($event){
			this.dadosPagamento.arquivo_comprovante = $event;
        },
        fileSelectedPagamentoSemFatura($event){
			this.dadosPagamentoSemFatura.arquivo_comprovante = $event;
        },
		abrirArquivo(subdiretorio, arquivo) {
			window.open(this.$http.options.root + 'resources/faturas_geradas/' + subdiretorio + '/' + arquivo, '_blank');
		},
		exportarListagemFiltros() {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			
			thisEl.$http.post(//requisição ajax
                'venda/exportar_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                    	thisEl.loadingStatus = false;

                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								thisEl.exportXlsAjax(dados, 'vendas', ['id_venda', 'id_status', 'id_cliente', 'id_tipo_cliente', 'id_vendedor', 'id_unidade_operacional', 'id_produto', 'quantidade', 'valor_unitario', 'valor_total']);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemGeral(dadosListagem) {
			let dados = {};

			dados.headers = Object.keys(dadosListagem[0]).map((item) => {
				return {name: item};
			});

			dados.values = dadosListagem;
			
			this.exportXlsAjax(dados, 'vendas', ['id_venda', 'id_status', 'id_cliente', 'id_tipo_cliente', 'id_vendedor', 'id_unidade_operacional', 'id_produto', 'quantidade', 'valor_unitario', 'valor_total']);
		},
		exportXlsAjax(dados, filename, numericHeaders){

			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
        changeProduto() {
        	this.buscarValorProdutoDoCliente();
        	this.preloadFieldsAutorizacaoEmplacamento();
        },
        buscarValorProdutoDoCliente () {
        	if (this.cadastro.id_produto != '' && this.cadastro.id_cliente != '') {
        		var thisEl = this;

        		let formData = thisEl.cadastro;
				formData.token = localStorage.getItem('token');

        		thisEl.$http.post(//requisição ajax
                    'venda/buscar_valor_produto_do_cliente',
                    formData,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            thisEl.cadastro.valor_unitario = r.body.valor;
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
        	}
        },
        preloadFieldsAutorizacaoEmplacamento() {
        	if (this.produtosQueVinculaAdicionalAutorizacaoEmplacamento.indexOf(Number(this.cadastro.id_produto)) >= 0) {
        		for (let i = 1; i <= this.cadastro.quantidade; i++) {
        			if (this.cadastro.autorizacao_emplacamento[i] == undefined) {
        				this.cadastro.autorizacao_emplacamento[i] = 'Não';
        			}
	        	}
        	}

        	//literalmente uma gambiarra para contornar bug do vuejs que não renderiza automaticamente o layout gerado pelo v-for
			this.showDialogCadastro = false;
			this.showDialogCadastro = true;
        },
        validatePreenchimentoPlacasCadastro() {
			//literalmente uma gambiarra porque o validade nativo não consegue validar campos criados dinamicamente
			let validate = true;
			
			if (this.produtosQueExigemPlaca.indexOf(Number(this.cadastro.id_produto)) >= 0) {//se produto selecionado exige preenchimento de placa
				console.log(this.cadastro.placas);
				if (this.cadastro.placas.length == 0) {//se não tem placas preenchidas
					validate = false;
				} else {
					for (let i = 1; i <= this.cadastro.quantidade; i++) {
						if (this.cadastro.placas[i] == undefined) {
							this.cadastro.placas[i] = '';
						}

						if (this.cadastro.placas[i].length < 7) {
							validate = false;
						}
					}
				}
			}

			if (validate == false) {
				this.snackbarColor = 'red';
  				this.snackbarText = 'Informe corretamente as placas!';
  				this.snackbarShower = true;
			}

			return validate;
		},
		validarFormCadastro() {
			let validate = false;
			if (this.$refs.formCadastramento.validate() && this.validatePreenchimentoPlacasCadastro()) {
				validate = true;
			}

			return validate
		},
		gatilhoSubmitCadastro() {
			this.submitCadastro();
		},
		gatilhoSubmitCadastroNovaVenda() {
			this.cadastro.lancarNovaVendaMesmoCliente = true;

			this.submitCadastro();
		},
		submitCadastro () {
			if (this.validarFormCadastro()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				let dataToSend = this.cadastro;
				dataToSend.token = localStorage.getItem('token');
				
				if (this.session.id_cargo_usuario == 3) {//quando vendedor que está criando, não aparece o seletor de vendedor na criação
					dataToSend.id_usuario_vendedor = this.session.id_usuario;//então setamos o vendedor como sendo o usuário logado
				}

				//resetando dados do formulario
				let reconstrucaoForm = {...this.cadastro_resetado};

				thisEl.$http.post(//requisição ajax
                    'venda/criar_registro_de_venda',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
	                        		thisEl.loadingStatus = false;

	                        		if (dataToSend.lancarNovaVendaMesmoCliente == true) {
										
										reconstrucaoForm = {...dataToSend};//replicando dados do formulário

										//resetando somente os dados relacionados ao produto
										reconstrucaoForm.id_produto = 0;
										reconstrucaoForm.quantidade = 1;
										reconstrucaoForm.placas = [];
										reconstrucaoForm.autorizacao_emplacamento = [];
										reconstrucaoForm.valor_unitario = 0;
										reconstrucaoForm.lancarNovaVendaMesmoCliente = false;
									} else {
										thisEl.showDialogCadastro = false;
									}

	                        		thisEl.cadastro = reconstrucaoForm;
	                        	}, 1000);

	                        	this.buscaListaComFiltros();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		registrarPagamentoSemFatura(dados) {
			this.dadosPagamentoSemFatura.id_venda = dados.id_venda;
			this.dadosPagamentoSemFatura.id_cliente = dados.id_cliente;
			this.dadosPagamentoSemFatura.id_tipo_cliente = dados.id_tipo_cliente;
			this.dadosPagamentoSemFatura.descritivo_pagamento = '';
			this.dadosPagamentoSemFatura.data_pagamento = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
			this.dadosPagamentoSemFatura.arquivo_comprovante = null;
			this.showDialogPagamentoSemFatura = true;
		},
		submitRegistroPagamentoSemFatura() {
			if (this.$refs.formPagamento.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				let formData = new FormData();
				formData.append('token', localStorage.getItem('token'));
				formData.append('id_venda', this.dadosPagamentoSemFatura.id_venda);
				formData.append('id_cliente', this.dadosPagamentoSemFatura.id_cliente);
				formData.append('id_tipo_cliente', this.dadosPagamentoSemFatura.id_tipo_cliente);
				formData.append('id_forma_pagamento', this.dadosPagamentoSemFatura.id_forma_pagamento);
				formData.append('descritivo_pagamento', this.dadosPagamentoSemFatura.descritivo_pagamento);
				formData.append('data_pagamento', this.dadosPagamentoSemFatura.data_pagamento);
				formData.append('arquivo_comprovante', this.dadosPagamentoSemFatura.arquivo_comprovante);

				thisEl.$http.post(//requisição ajax
                    'venda/efetuar_pagamento_venda_sem_fatura',
                    formData,
                    {
                    	headers: {'Content-Type': 'multipart/form-data'}
                    }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.loadingStatus = false;
	                        		thisEl.showDialogPagamentoSemFatura = false;
	                        		thisEl.dadosPagamentoSemFatura = thisEl.dadosPagamentoSemFatura_resetado;

	                        		thisEl.fileNameholder = null;
	                        	}, 1000);

                            	this.buscaListaComFiltros();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		registrarPagamento(dados) {
			this.dadosPagamento.id_fatura = dados.id_fatura;
			this.dadosPagamento.descritivo_pagamento = '';
			this.dadosPagamento.data_pagamento = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
			this.dadosPagamento.arquivo_comprovante = null;
			this.showDialogPagamento = true;
		},
		submitRegistroPagamento() {
			if (this.$refs.formPagamento.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				let formData = new FormData();
				formData.append('token', localStorage.getItem('token'));
				formData.append('id_fatura', this.dadosPagamento.id_fatura);
				formData.append('id_forma_pagamento', this.dadosPagamento.id_forma_pagamento);
				formData.append('descritivo_pagamento', this.dadosPagamento.descritivo_pagamento);
				formData.append('data_pagamento', this.dadosPagamento.data_pagamento);
				formData.append('arquivo_comprovante', this.dadosPagamento.arquivo_comprovante);

				thisEl.$http.post(//requisição ajax
                    'venda/efetuar_pagamento_fatura',
                    formData,
                    {
                    	headers: {'Content-Type': 'multipart/form-data'}
                    }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.loadingStatus = false;
	                        		thisEl.showDialogPagamento = false;
	                        		thisEl.dadosPagamento = thisEl.dadosPagamento_resetado;

	                        		thisEl.fileNameholder = null;
	                        	}, 1000);

                            	this.buscaListaComFiltros();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		cancelarFatura(dados) {
			this.dadosCancelarFatura = {...dados};
			this.showDialogCancelarFatura = true;
		},
		submitCancelarFatura() {
			this.loadingStatus = true;

			var thisEl = this;

			var dataToSend = this.dadosCancelarFatura;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
                'venda/cancelar_fatura',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogCancelarFatura = false;
                        	}, 1000);

                        	this.buscaListaComFiltros();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		excluirVenda(dados) {
			this.dadosExclusao = {...dados};
			this.showDialogExcluir = true;
		},
		submitExclusao() {
			this.loadingStatus = true;

			var thisEl = this;

			var dataToSend = this.dadosExclusao;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
                'venda/excluir_venda',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogExcluir = false;
                        	}, 1000);

                        	this.buscaListaComFiltros();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		atualizarValorAutorizacaoEmplacamento() {
			this.dadosEdicao.valor_autorizacao_emplacamento = 0;
			if (this.dadosEdicao.autorizacao_emplacamento == 'Sim') {
				this.dadosEdicao.valor_autorizacao_emplacamento = this.data.valorAtualAutorizacaoEmplacamento.valor;
			}
		},
		editarVenda (dados) {
			this.dadosEdicao = {...dados};
			this.showDialogEdit = true;
		},
		submitEdicao () {
			if (this.$refs.formEdicao.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.dadosEdicao;
				dataToSend.data_venda = dataToSend.data_venda_sem_formatacao;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'venda/atualizar_venda',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.buscaListaComFiltros();

                            		thisEl.loadingStatus = false;
	                        		thisEl.showDialogEdit = false;
	                        	}, 1000);
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		emitirFaturaCliente (dados) {
			this.dadosEmissaoFatura = {...dados};
			this.dadosEmissaoFatura.nome_cliente = this.dadosEmissaoFatura.nome;
			this.utilizarSaldoFinanceiroCliente = 'Não';
			this.desconto_acrescimo_valor = 0;

			this.vendasSelecionadas = [];
			this.vendasSelecionadas.push(this.dadosEmissaoFatura);

			this.buscaVendasEmAbertoDoClienteNaUnidadeOperacional();
			
			this.showDialogEmissaoFatura = true;
		},
		buscaVendasEmAbertoDoClienteNaUnidadeOperacional() {
			var thisEl = this;

			var dataToSend = {};
			dataToSend.token = localStorage.getItem('token');
			dataToSend.id_unidade_operacional = this.dadosEmissaoFatura.id_unidade_operacional;
			dataToSend.id_cliente = this.dadosEmissaoFatura.id_cliente;
			dataToSend.id_tipo_cliente = this.dadosEmissaoFatura.id_tipo_cliente;
			dataToSend.id_cargo_usuario_emissor = this.session.id_cargo_usuario;
			dataToSend.id_usuario_emissor = this.session.id_usuario;

			this.listagemVendasEmAbertoDoCliente = [{id_venda: 'Buscando...'}];
			this.saldoFinanceiroCliente = 0;

			thisEl.$http.post(//requisição ajax
	            'venda/listagem_vendas_em_aberto_cliente_unidade_operacional',
	            dataToSend,
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.listagemVendasEmAbertoDoCliente = r.body.listagemVendasEmAbertoDoCliente;
	                	thisEl.saldoFinanceiroCliente = r.body.saldoFinanceiroDoCliente;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		submitCriacaoFatura() {
			var thisEl = this;
			this.loadingStatus = true;

			var dataToSend = {};
			dataToSend.token = localStorage.getItem('token');
			dataToSend.id_cliente = this.dadosEmissaoFatura.id_cliente;
			dataToSend.id_tipo_cliente = this.dadosEmissaoFatura.id_tipo_cliente;
			dataToSend.ids_vendas = this.ids_vendas_selecionadas;
			dataToSend.desconto_acrescimo_descritivo = this.desconto_acrescimo_descritivo;
			dataToSend.desconto_acrescimo_valor = this.desconto_acrescimo_valor;
			dataToSend.utilizarSaldoFinanceiroCliente = this.utilizarSaldoFinanceiroCliente;

			thisEl.$http.post(//requisição ajax
	            'venda/emitir_fatura_cliente',
	            dataToSend,
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.success) {
                    	thisEl.snackbarColor = 'green';
	      				thisEl.snackbarText = r.body.message;
                    	thisEl.snackbarShower = true;

                    	setTimeout(function(){
                    		thisEl.loadingStatus = false;
                    		thisEl.showDialogEmissaoFatura = false;

                    		thisEl.desconto_acrescimo_descritivo = '';
                    		thisEl.desconto_acrescimo_valor = 0;

                    		window.open(thisEl.$http.options.root + 'resources/faturas_geradas/arquivos_fatura/' + r.body.filename, '_blank');
                    	}, 1000);

                    	this.buscaListaComFiltros();
                    } else {
                    	thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
                    }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		calcularFaturaComSaldoFinanceiroCliente() {
			if (this.utilizarSaldoFinanceiroCliente == 'Sim') {
				this.desconto_acrescimo_descritivo = 'Compensação de saldo existente';
				this.desconto_acrescimo_valor = Number(this.saldoFinanceiroCliente) * -1;
			} else {
				this.desconto_acrescimo_descritivo = '';
				this.desconto_acrescimo_valor = 0;
			}
		},
		limparFiltro() {
			var thisEl = this;
			Object.keys(thisEl.filters).forEach(function(key) {
				switch (key) {
					case 'ano':
						thisEl.filters[key] = new Date().getFullYear().toString();//por padrão traz o ano atual
						break;
					case 'meses':
						thisEl.filters[key] = [new Date().getMonth() + 1];//por padrão traz o mes atual
						break;
					default:
						thisEl.filters[key] = '';
				}
				
			});
		},
		buscaListaComFiltros() {
			this.data.listagemPessoaFisicaEJuridica = [{cnpj_cpf: 'Buscando...'}];

			this.buscaListaDefault({
				ano: this.filters.ano,
				meses: this.filters.meses
			});
		},
		buscaListaDefault(post = {}) {
			var thisEl = this;

			var dataToSend = post;
			dataToSend.token = localStorage.getItem('token');
			dataToSend.id_cargo_usuario = this.session.id_cargo_usuario;
			dataToSend.id_usuario = this.session.id_usuario;

			thisEl.$http.post(//requisição ajax
	            'venda/listagem_vendas',
	            dataToSend,
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemPessoaFisicaEJuridica = r.body.listagemPessoaFisicaEJuridica;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaFiltros() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'venda/listagem_filtros',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemTiposClientes = r.body.listagemTiposClientes;
	                	thisEl.data.listagemClientesPessoaFisica = r.body.listagemClientesPessoaFisica;
						thisEl.data.listagemClientesPessoaJuridica = r.body.listagemClientesPessoaJuridica;
						thisEl.data.listagemClientesPessoaFisicaEJuridica = r.body.listagemClientesPessoaFisicaEJuridica;
						thisEl.data.listagemVendedores = r.body.listagemVendedores;
						thisEl.data.listagemProdutos = r.body.listagemProdutos;
						thisEl.data.listagemUnidadesOperacionais = r.body.listagemUnidadesOperacionais;
						thisEl.data.listagemUnidadesPorVendedor = r.body.listagemUnidadesPorVendedor;
						thisEl.data.listaAnosComRegistroDeVenda = r.body.listaAnosComRegistroDeVenda;
						thisEl.data.listagemStatusVendas = r.body.listagemStatusVendas;
						thisEl.data.listagemFormasPagamento = r.body.listagemFormasPagamento;
						thisEl.data.valorAtualAutorizacaoEmplacamento = r.body.valorAtualAutorizacaoEmplacamento;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		v_number_format (number, decimals, dec_point, thousands_sep) {
			return number_format(number, decimals, dec_point, thousands_sep);
		},
		formatDate (date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		mascara_cnpj_cpf(cnpj_cpf) {
			if (cnpj_cpf != undefined) {
				if (cnpj_cpf.length == 11) {//cpf
					return cnpj_cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
				} else {//cnpj
					return cnpj_cpf.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
				}
			}
		},
	},
	computed: {
		mesesComId() {
            var obj = [];

            this.meses.forEach(function(item, key){
                obj.push({id_mes: key+1, desc_mes: item});
            });

            return obj;
        },
        todosMesesSelecionados () {
			return this.filters.meses.length === this.mesesComId.length
		},
		algunsMesesSelecionados () {
			return this.filters.meses.length > 0 && !this.todosMesesSelecionados
		},
		icon () {
			if (this.todosMesesSelecionados) return 'mdi-close-box'
			if (this.algunsMesesSelecionados) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		},
		dataVendaToBR() {
			return this.formatDate(this.cadastro.data_venda);
		},
		dataVendaToBREdicao() {
			return this.formatDate(this.dadosEdicao.data_venda_sem_formatacao);
		},
		dataVendaToBRFiltroCriacao: {
			get: function() {
				let data = null;

				if (this.filters.data_venda) {
					data = this.formatDate(this.filters.data_venda.substr(0, 10));
				}

				return data;
			},
			set: function(newValue) {
				this.filters.data_venda = newValue;
			}
		},
		dataToBRFiltroPagamento: {
			get: function() {
				let data = null;

				if (this.filters.data_pagamento) {
					data = this.formatDate(this.filters.data_pagamento.substr(0, 10));
				}

				return data;
			},
			set: function(newValue) {
				this.filters.data_pagamento = newValue;
			}
		},
		produtosQueExigemPlaca() {
			let ids_produtos = [];
			this.data.listagemProdutos.forEach(function(item, key){
				if (Number(item.informar_placa_na_venda) == 1) {
					ids_produtos.push(Number(item.id_produto));
				}
			});
			
			return ids_produtos;
		},
		produtosQueVinculaAdicionalAutorizacaoEmplacamento() {
			let ids_produtos = [];
			this.data.listagemProdutos.forEach(function(item, key){
				if (Number(item.informar_autorizacao_emplacamento_na_venda) == 1) {
					ids_produtos.push(Number(item.id_produto));
				}
			});
			
			return ids_produtos;
		},
		data_pagamentoToBR() {
			return this.formatDate(this.dadosPagamento.data_pagamento);
		},
		data_pagamentoToBRSemFatura() {
			return this.formatDate(this.dadosPagamentoSemFatura.data_pagamento);
		},
		valor_total_autorizacao_emplacamento() {
			let objPrincipal = this.cadastro;
			if (this.showDialogEdit) {
				objPrincipal = this.dadosEdicao;
			}

			let valorTotal = 0;

			if (this.produtosQueVinculaAdicionalAutorizacaoEmplacamento.indexOf(Number(objPrincipal.id_produto)) >= 0) {
				for (let i = 0; i <= Number(objPrincipal.quantidade); i++) {
					if (objPrincipal.autorizacao_emplacamento[i] == 'Sim') {
						valorTotal += Number(this.data.valorAtualAutorizacaoEmplacamento.valor);
					}
				}
			}

			return valorTotal.toFixed(2);
		},
		valor_total_produto_selecionado_e_quantidade_por_cliente() {
			let objPrincipal = this.cadastro;
			if (this.showDialogEdit) {
				objPrincipal = this.dadosEdicao;
			}

			let valorTotal = objPrincipal.valor_unitario * objPrincipal.quantidade;

			if (this.produtosQueVinculaAdicionalAutorizacaoEmplacamento.indexOf(Number(objPrincipal.id_produto)) >= 0) {
				for (let i = 0; i <= Number(objPrincipal.quantidade); i++) {
					if (objPrincipal.autorizacao_emplacamento[i] == 'Sim') {
						valorTotal += Number(this.data.valorAtualAutorizacaoEmplacamento.valor);
					}
				}
			}

			return valorTotal.toFixed(2);
		},
		listagemUnidadesDoVendedorSelecionado() {
			let thisEl = this;
			let listaUnidades = [];
			
			if (this.session.id_cargo_usuario == 3) {
				this.cadastro.id_usuario_vendedor = this.session.id_usuario;
			}

			this.data.listagemUnidadesPorVendedor.forEach(function(item, key){
				if (item.id_usuario == Number(thisEl.cadastro.id_usuario_vendedor)) {
					listaUnidades.push(item);
				}
			});

			if (listaUnidades.length == 1) {
				thisEl.cadastro.id_unidade_operacional = listaUnidades[0].id_unidade_operacional;
			} else {
				thisEl.cadastro.id_unidade_operacional = 0;
			}

			return listaUnidades;
		},
		valorSubTotalDaFatura() {
			let valorTotal = 0;

			this.vendasSelecionadas.forEach(function(item, key){
				valorTotal += Number(item.valor_total);
			});

			return valorTotal;
		},
		valorTotalDaFatura() {
			return this.valorSubTotalDaFatura + Number(this.desconto_acrescimo_valor);
		},
		ids_vendas_selecionadas() {
			let ids_vendas = [];

			this.vendasSelecionadas.forEach(function(item, key){
				ids_vendas.push(item.id_venda);
			});

			return ids_vendas;
		},
		listagemHeadersPessoaFisicaEJuridica() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Venda',
					value: 'id_venda',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Status',
					value: 'desc_status',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.desc_status) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.desc_status.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Data da Venda',
					value: 'data_venda',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.data_venda) {
							return true;
						}

						return (value != null && value.toUpperCase().indexOf(this.formatDate(this.filters.data_venda)) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Nome Cliente',
					value: 'nome',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome) {
							return true;
						}

						return (value != null && value.toUpperCase().indexOf(this.filters.nome.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Observação',
					value: 'observacao',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.observacao) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.observacao.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Produto',
					value: 'desc_produto',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.desc_produto) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.desc_produto.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Placa',
					value: 'placa',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.placa) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.placa.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Valor Total',
					value: 'valor_total',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Endereço entrega',
					value: 'endereco',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.endereco) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.endereco.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Vendedor',
					value: 'nome_vendedor',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome_vendedor) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome_vendedor.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Unidade Operacional',
					value: 'nome_fantasia_unidade_operacional',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome_fantasia_unidade_operacional) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome_fantasia_unidade_operacional.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Valor Unitário',
					value: 'valor_unitario',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'AE Vinculada?',
					value: 'autorizacao_emplacamento',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Valor AE',
					value: 'valor_autorizacao_emplacamento',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'CPF / CNPJ',
					value: 'cnpj_cpf',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.cnpj_cpf) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.cnpj_cpf.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Data do Pagamento',
					value: 'data_pagamento',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.data_pagamento) {
							return true;
						}

						return (value != null && new Date(value).toLocaleDateString("pt-BR") == this.formatDate(this.filters.data_pagamento)) ? true : false;
					}
				},
				{
					text: 'Usuário Registro Pagamento',
					value: 'nome_usuario_registro_pagamento',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Registro Pagamento',
					value: 'dt_registro_pagamento',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Alteração',
					value: 'dt_alteracao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Alterador',
					value: 'nome_usuario_alterador',
					class: 'blue darken-4 white--text'
				}
			];
		},
		listHeadersVendasCliente() {
			return [
				{
					text: 'ID Venda',
					value: 'id_venda',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Produto',
					value: 'desc_produto',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Valor Unitário',
					value: 'valor_unitario',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Valor AE',
					value: 'valor_autorizacao_emplacamento',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Valor Total',
					value: 'valor_total',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data da Venda',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
		this.buscaFiltros();
	}
};
</script>
